/* colors */
$tanned: #f8f2ea;
$charred: #292924;
$burnt: #c16a2a;
$singed: #a1957c;
$dusky: #89a2b6;

/* Fonty */

body {
    font-family: "Figtree", sans-serif;
    color: $charred;
    background: $tanned;
}

h1 {
    font-size: 29px;
    font-weight: 800;
    line-height: 1.2em;
}

h2 {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.4em;
    margin-bottom: 1em;
}

h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 7px;
}

h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 1em 0 0;
}

p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6em;
    text-align: left;
    margin-bottom: 29px;

    &.lead {
      margin-bottom: 0;
      font-size: 180%;
      font-weight: 700;
    }
    &.top-0 {
      margin-top: 0;
    }
}

a {
  color: $dusky;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

strong {
    font-weight: 600;
}

img {
    width: 100%;
    max-width: 100%;
}

ol, ul {
    margin-bottom: 29px;
    margin-left: 1.2em;
}

ol li, ol li p, ul li, ul li p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 1.4em;
}

ol li {
    list-style: decimal;
}

ul li {
    list-style: initial;
}

hr {
  border: none;
  height: 1px;
  background-color: $charred;
}

blockquote {
  p {
    color: $burnt; 
  }
}

.text-large {
    font-size: 29px;
}

@media only screen and (min-width: 960px) {
    h1 {
      font-size: 61px;
    }
  
    h2 {
      font-size: 42px;
    }
  
    h3 {
      font-size: 29px;
    }
  
    h4 {
      font-size: 20px;
    }
  
    p {
      font-size: 20px;

      &.project-role {
        border-top: 1px solid $dusky;
        padding-top: 20px;
        color: $dusky;
      }
    }
  }

  /* Helpers */
  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .no-bullets {
    list-style: none;
  }

  img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  /* Flex grid */
  .grid-container {
    @media only screen and (min-width: 960px) {
      display: flex;
      justify-content: center;
      // gap: 1rem 2rem;

      &.align-items-center {
        align-items: center;
      }
    }
    .cell {
      @media only screen and (min-width: 960px) {
        &.medium-3 {
          width: 33.333%;
        }
        &.medium-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }

  .grid-block {
    @media only screen and (min-width: 960px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
    }
    
    .cell {
      @media only screen and (min-width: 960px) {
        max-width: 22%;
      }
      padding: 1rem;
    }
  }

  /* Layouts */
  body {
    margin: 0;
  }
  
  .container {
    max-width: 1740px;
    padding: 0 2%;
    margin-top: 36px;
    margin-right: auto;
    margin-bottom: 100px;
    margin-left: auto;

    &.article {
      max-width: 666px;
    }

    &.mt-0 {
      margin-top: 0;
    }
    &.mb-0 {
      margin-bottom: 0;
    }
    &.pt-2 {
      padding-top: 2rem;
    }
    &.pb-2 {
      padding-bottom: 2rem;
    }
  }

  .header-big {
    // max-width: 900px;
    margin: 15vh auto;
    padding: 0 2%;
    min-width: none;
    min-height: 30vh;

    h1 {
        font-weight: 700;
        font-size: 29px;
        font-family: "Fredoka", sans-serif;
    }
  }

  .burnt-ends {
    background-color: $singed;
    color: $charred;
  }

  .site_footer {
    padding: 24px 2%;
    text-align: center;
    background: $charred;
    color: $tanned;

    p {
      font-size: 14px;
      text-align: center;
      margin: 0;
    }
  }

  .vh-75 {
    min-height: 75vh;
  }

  .vh-100 {
    height: 100vh;
  }

  /* Navigation */
  .site_nav {
    max-width: 900px;
    margin: 0 auto 36px auto;
    text-align: center;
    
    ol {
      margin: 0 1rem 0 0;
      padding: 0;
      list-style: none;
      position: relative;
      height: 100%;

      li {
        display: inline-block;
        transition: all .2s ease-in;
        padding-bottom: 3px;

        &:hover {
          border-bottom: 3px solid $burnt;
          color: $burnt;
          padding-bottom: 0;
        }

        a {
          margin: 0 1rem;
          text-decoration: none;
          overflow: hidden;
          line-height: 3rem;
          color: $dusky;

          &:hover {
            color: $burnt;
          }

          .social-link {
            &:hover {
              border-bottom: none;
            }
          }
        }
      }
    }
  }



  .logo {
    max-width: 200px;
    margin: 0 auto;
  }

  .portrait {
    max-width: 250px;
    box-shadow: 1px 5px 5px #777;

    &.circle {
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .blanch {
    background: #ffffff;

    h2 {
      margin-top: 0;
      padding-top: 2em;
    }
  }